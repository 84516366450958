import * as React from 'react';
import Link from 'gatsby-link';

import './styles.scss';

export const Footer = () => (
  <div className="footer">
    <a href="https://techtheory.io" className="flex-1">
      TechTheory
    </a>
    <Link to="/imprint">Impressum</Link>
    <Link to="/privacyNotice">Datenschutz</Link>
  </div>
);
